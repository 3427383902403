<template>
  <div id="About">
    <div id="About_Cover">
      <img :src="GetFullPath(about_cover)" :alt="about_cover_alt" />
    </div>
    <div class="row">
      <h2 class="title_font">關於我們</h2>
      <div class="content content_font" v-html="about_content"></div>

      <div class="company_info_table">
        <h2 class="title_font">公司資訊</h2>
        <div class="info_group content_font">
          <label>公司名稱</label>
          <p>{{ company_data.company_name.Content }}</p>
        </div>
        <div class="info_group content_font">
          <label>設立日期</label>
          <p>{{ company_data.company_date.Content }}</p>
        </div>
        <div class="info_group map content_font">
          <label>公司登記地址</label>
          <div>
            <div v-html="company_data.company_google_map.Content"></div>
            <p>{{ company_data.company_address.Content }}</p>
          </div>
        </div>
        <div class="info_group content_font">
          <label>聯絡電話</label>
          <p>{{ company_data.company_phone.Content }}</p>
        </div>
        <!-- <div class="info_group content_font">
          <label>資本額</label>
          <p>{{ company_data.company_capital.Content }}</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'About',
  data() {
    return {
      about_content: '',
      about_cover: '',
      about_cover_alt: '',
      meta_data: null,
    };
  },
  methods: {
    async LoadData() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/column', 'post', {
        Data: ['about_content', 'about_cover', 'about_cover_alt'],
      });
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        this.about_content = response.about_content.Content;
        this.about_cover = response.about_cover.Image;
        this.about_cover_alt = response.about_cover_alt.Content;
        this.meta_data = GetMetaData(
          '關於我們',
          this.about_content.replace(/<[^>]+>/g, ''),
          ''
        );
        window.prerenderReady = true;
      }
    },
  },
  created() {
    this.LoadData();
  },
  computed: {
    company_data() {
      return this.$store.state.company_info;
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
